import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import * as React from 'react';
import { TDCSContext } from 'Routes/Tdcs/Document';
import { styled } from '@mui/system';
import colors from '../../../../../../../config/theme/colors';
import { Container, Item } from 'Components/Grid';
import { capitalize } from 'Utility/string';
import { useTabulationTools } from 'Utility/customHooks';

const InputContainer = styled('div')({
  margin: '0 0 2.5rem 0.9rem',
});

const InputHeaderContainer = styled(InputContainer)({
  color: colors.tertiaryText,
});

const InputTitleContainer = styled(Item)({
  paddingLeft: '1.5rem',
  color: colors.tertiaryText,
});

const PatientsRating = ({ document, onChange, editingEvent = true }: IPatientsRating): JSX.Element => {
  const tdcsContext = React.useContext(TDCSContext);
  const { isEditing, fm } = tdcsContext;

  // onChange function for individual patient's rating values
  const onChangeArray = (value: TOnChangeValues): void => {
    const name = Object.keys(value)[0];
    const path = name.split('.');
    if (path.length !== 4) return;
    // path[0]: 'ratingByLocation'
    const newValues = JSON.parse(JSON.stringify(document[path[0] as keyof ITDCSRating] ?? []));
    // path[1]:  index
    // path[2]: 'intensity' or 'harm'
    // path[3]: 'beforeTreatment' or 'afterTreatment'
    newValues[parseInt(path[1])][path[2]][path[3]] = value[name];
    onChange({ [path[0]]: newValues });
  };

  type TName = 'intensity' | 'harm';
  type TPeriod = 'beforeTreatment' | 'afterTreatment';

  const { symptom, ratingByLocation } = document;
  const noLocation = symptom === 'other';

  const { currentId, inputRefs, onKeyDown } = useTabulationTools({
    currentIdStart: '10',
    childrenLength: ratingByLocation ? ratingByLocation.length * 2 + 1 : 0,
  });

  // Used to calculate row indexes through entire table
  let rowIndex = 0;

  return (
    <div onKeyDown={onKeyDown} style={{ padding: '2rem 0 0 0' }}>
      {ratingByLocation?.map((rating: TTDCSRatingByLocation, index: number) => {
        return (
          <React.Fragment key={index}>
            {symptom === 'other' && rating.description && rating.description.length > 0 && (
              <FormRow title="rtms.patientsRating.symptomDescription" bottomMargin={false}>
                <InputContainer style={{ maxWidth: '35rem', fontWeight: 600 }}>{rating.description}</InputContainer>
              </FormRow>
            )}
            <Container>
              <Item xs={5} style={{ fontWeight: 600 }}>
                {noLocation ? '' : rating.location ? fm(`tdcs.patientsRating.opts.${rating.location}`) : '-'}
              </Item>
              <Item xs={3} style={{ visibility: index > 0 ? 'hidden' : undefined }}>
                <InputHeaderContainer>{fm('tdcs.patientsRating.beforeTreatment')}</InputHeaderContainer>
              </Item>
              <Item xs={3} style={{ visibility: index > 0 ? 'hidden' : undefined }}>
                <InputHeaderContainer>{fm('tdcs.patientsRating.afterTreatment')}</InputHeaderContainer>
              </Item>
            </Container>
            {['intensity', 'harm'].map((name, ind) => {
              if (rowIndex < ratingByLocation.length * 2) rowIndex++;
              return (
                <Container key={ind}>
                  <InputTitleContainer xs={5}>
                    {fm(`tdcs.patientsRating.${symptom === 'other' ? 'symptom' : symptom}${capitalize(name)}`)}
                  </InputTitleContainer>
                  {['beforeTreatment', 'afterTreatment'].map((period, i) => (
                    <Item xs={3} key={i}>
                      <InputContainer>
                        <InputHandler
                          type="NumberField"
                          name={`ratingByLocation.${index}.${name}.${period}`}
                          editing={isEditing && editingEvent}
                          formData={{
                            document: {
                              ratingByLocation: Object.assign([], ratingByLocation, {
                                [index]: {
                                  ...rating,
                                  [name]: {
                                    ...rating[name as TName],
                                    [period]: rating[name as TName]?.[period as TPeriod],
                                  },
                                },
                              }),
                            },
                            onChange: onChangeArray,
                          }}
                          min={0}
                          max={10}
                          width={10}
                          placeholder="0-10"
                          disablePlaceholderFormatting
                          tabulationTools={{
                            tabIndex: `${rowIndex}${i}` === currentId ? 0 : -1,
                            inputRefs,
                            identifier: `${rowIndex}${i}`,
                          }}
                        />
                      </InputContainer>
                    </Item>
                  ))}
                </Container>
              );
            })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

interface IPatientsRating {
  document: ITDCSRating;
  onChange: (value: TOnChangeValues) => void;
  editingEvent?: boolean;
}

export default PatientsRating;
