import * as React from 'react';
import MGCHistory from './HistoryRowData/MGCHistory';
import { TDocument, getHeaderId, getHeaderInfotext } from '../utils';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import DocumentWrapper from '../../../components/DocumentWrapper';
import DocumentHeader from '../../../components/DocumentHeader';
import Form from './Form';
import { find } from 'ramda';
import HistoryTabs from 'Components/HistoryTabs';
import { FormattedMessage } from 'react-intl';

const FunctionalPerformanceHistory = ({
  documents,
  startEdit,
  view,
  openTab,
  setOpenTab,
}: {
  documents: Array<TDocument>;
  startEdit: (document: TAnyObject) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
  openTab: number;
  setOpenTab: (i: number) => void;
}): JSX.Element => {
  const MGCDocs = documents.filter((d) => d._type === 'mgc') as IMGC[];

  return (
    <React.Fragment>
      <HistoryTabs indexSelectionTools={{ index: openTab, changeFunction: (index) => setOpenTab(index) }}>
        {[
          {
            title: 'functionalPerformanceMgravis.mgcShort',
            content: <MGCHistory documents={MGCDocs} startEdit={startEdit} view={view} />,
          },
        ]}
      </HistoryTabs>
    </React.Fragment>
  );
};

const FunctionalPerformanceMgravis = ({ documents }: IOwnProps): JSX.Element => {
  const [openTab, setOpenTab] = React.useState<number>(0);
  return (
    <FormEditingHandler name="functionalPerformanceMgravis" documents={documents}>
      {(editing, startEdit, formData, view): JSX.Element => {
        const infoText = getHeaderInfotext(documents, editing, view?.viewing);
        return (
          <DocumentWrapper>
            <DocumentHeader
              name="functionalPerformanceMgravis"
              headerId={getHeaderId(documents, editing, view?.viewing)}
              editing={editing}
              editButtons={<div />}
              infoText={
                infoText ? (
                  <FormattedMessage
                    id={infoText}
                    values={{
                      br: <br />,
                    }}
                  />
                ) : undefined
              }
            />
            {editing ? (
              <Form
                formData={formData}
                document={documents && (find((d: TDocument) => d._id === editing, documents) as TDocument)}
              />
            ) : null}
            {view?.viewing ? (
              <Form
                formData={{ document: view.document, onChange: (): string => '' }}
                document={documents && (find((d: TDocument) => d._id === view?.viewing, documents) as TDocument)}
                viewing={!!view.viewing}
              />
            ) : null}
            {!editing && !view?.viewing ? (
              <FunctionalPerformanceHistory
                documents={documents}
                startEdit={startEdit}
                view={view}
                openTab={openTab}
                setOpenTab={setOpenTab}
              />
            ) : undefined}
          </DocumentWrapper>
        );
      }}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default FunctionalPerformanceMgravis;
