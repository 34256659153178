import { path } from 'ramda';
import * as React from 'react';
import { TDocument } from '../../utils';
import MGCForm from './MGC';

const FunctionalPerformanceForm = ({ formData, document, viewing = false }: IOwnProps): JSX.Element | null =>
  path(['_type'], document) === 'mgc' ? <MGCForm formData={formData as IFormData<IMGC>} viewing={viewing} /> : null;

interface IOwnProps {
  formData: IFormData<TDocument>;
  document: TDocument;
  viewing?: boolean;
}

export default FunctionalPerformanceForm;
